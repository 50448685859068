import React from 'react';
import RouterLink from 'next/link';

import { yup } from '@/util/yup';
import { urls } from '@/constants';
import { sendMessage, MessageSendRequest } from '@/services/messages';

import { withLabel } from '@/components/hoc/withLabel';
import Content from '@/components/Content';
import Typography from '@/components/Typography';
import Form from '@/components/Form';
import TextInput, { Props as TextInputProps } from '@/components/Form/TextInput';
import AutoHeightInput from '@/components/Form/AutoHeightInput';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';
import Link from '@/components/Link';

import style from './ContactForm.sass';
import { RECAPTCHA_SITE_KEY } from '@/config';

const TextInputWithLabel = withLabel(TextInput);
const TextAreaWithLabel = withLabel<TextInputProps>((props) => <AutoHeightInput {...props} is="textarea" />);

const schema = yup
  .object({
    email: yup
      .string()
      .email()
      .required()
      .label('email'),
    name: yup.string().required(),
    message: yup.string().required()
  })
  .required();

export interface Props {}

const ContactForm: React.FC<Props> = () => {
  const getReCaptchaToken = () => {
    return new Promise<string>((resolve, reject) => {
      // @ts-ignore
      const grecaptcha = global.grecaptcha;

      if (!grecaptcha) return reject(new Error(`ReCaptcha is not initialized.`));

      const timeout = setTimeout(() => reject(new Error(`ReCaptcha failed to execute in 5 seconds.`)), 5000);

      grecaptcha.ready(() => {
        grecaptcha
          .execute(RECAPTCHA_SITE_KEY, { action: 'contact' })
          .then((token: string) => {
            clearTimeout(timeout);
            return resolve(token);
          })
          .catch((error: Error) => {
            clearTimeout(timeout);
            return reject(error);
          });
      });
    });
  };

  const submit = React.useCallback(
    (values: Omit<MessageSendRequest, 'token'>) =>
      getReCaptchaToken()
        .then((token) => sendMessage({ ...values, token }))
        .then(() => null),
    [getReCaptchaToken]
  );

  return (
    <Content>
      <Spacer xs={4} type="margin">
        <Typography is="h1" type="h1" color="primary" underline>
          Let&apos;s break the ice!
        </Typography>
      </Spacer>

      <Spacer xs={4}>
        <Typography is="p" type="p" color="secondary">
          Do you have a cool product idea in your mind and you are looking for someone to bring it to life? 👋 Feel free
          to get in touch with me!
        </Typography>
      </Spacer>

      <Spacer xs={6}>
        <Typography is="p" type="p" color="secondary">
          If you don&apos;t like forms just drop me an e-mail at{' '}
          <Link is="a" href={`mailto:${urls.email}`} target="_blank">
            {urls.email}
          </Link>
          .
        </Typography>
      </Spacer>

      <div className={style.content}>
        <Form schema={schema} subscription={{ submitting: true, submitSucceeded: true }} onSubmit={submit}>
          {({ handleSubmit, submitting, submitSucceeded }) => (
            <Spacer xs={2}>
              <form onSubmit={handleSubmit} spellCheck={false} noValidate>
                <Spacer xs={6}>
                  <Form.Field
                    is={TextInputWithLabel}
                    id="name"
                    name="name"
                    type="text"
                    placeholder="John Wick"
                    label="Name"
                    readOnly={submitting || submitSucceeded}
                    autoComplete="name"
                    autoCapitalize="words"
                  />
                </Spacer>

                <Spacer xs={6}>
                  <Form.Field
                    is={TextInputWithLabel}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="john.wick@email.com"
                    label="Email"
                    readOnly={submitting || submitSucceeded}
                    autoComplete="email"
                  />
                </Spacer>

                <Spacer xs={6}>
                  <Form.Field
                    is={TextAreaWithLabel}
                    id="message"
                    name="message"
                    placeholder="your message"
                    label="Message"
                    readOnly={submitting || submitSucceeded}
                    autoComplete="off"
                  />
                </Spacer>

                <Button appearance="primary" size="large" block loading={submitting} disabled={submitSucceeded}>
                  {submitSucceeded ? `Thank you!` : `Send message`}
                </Button>

                {submitSucceeded && (
                  <React.Fragment>
                    <Spacer xs={2} />

                    <Typography is="p" color="primary">
                      Nice one! I will get back to you soon.
                    </Typography>
                  </React.Fragment>
                )}
              </form>
            </Spacer>
          )}
        </Form>

        <Spacer xs={2}>
          <Typography is="p" color="ternary">
            By clicking the button I agree with the collection and processing of my personal data as described in the{' '}
            <RouterLink {...urls.legal('privacy-policy')} passHref>
              <Link is="a">Privacy Policy</Link>
            </RouterLink>
            .
          </Typography>
        </Spacer>

        <Typography is="p" color="ternary">
          This site is protected by reCAPTCHA and the Google{' '}
          <Link is="a" href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link is="a" href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </Link>{' '}
          apply.
        </Typography>
      </div>
    </Content>
  );
};

export default ContactForm;
