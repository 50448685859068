import React from 'react';
import { Field, FieldProps } from 'react-final-form';

import Is, { Props as IsProps, IsComponent } from '@/components/Is';

export type Props<T extends IsComponent> = Exclude<FieldProps<any, any>, IsProps<T>> &
  IsProps<T> & {
    name: string;
    type?: string;
    onFocus?: (event: Event) => any;
    onBlur?: (event: Event) => any;
    onChange?: (event: Event) => any;
  };

export default class FormField<T extends IsComponent> extends React.PureComponent<Props<T>> {
  static defaultProps: Pick<Props<any>, 'onFocus' | 'onBlur' | 'onChange'> = {
    onFocus: () => true,
    onBlur: () => true,
    onChange: () => true
  };

  handler = (fieldCallback: (event: any) => any, propCallback: (event: any) => any) => (event: Event) => {
    fieldCallback(event);
    if (propCallback) propCallback(event);
  };

  render() {
    const { name, type, value, isEqual, ...rest } = this.props;

    return (
      <Field name={name} value={value} type={type} isEqual={isEqual}>
        {({ input: { onFocus, onBlur, onChange, ...inputRest }, meta }) => {
          const invalid = meta.submitError ? !!meta.submitError && !meta.dirtySinceLastSubmit : meta.invalid;
          const errorVisible = !meta.active && meta.touched && invalid;
          const errorMessage = errorVisible ? meta.error || meta.submitError : null;

          return (
            // @ts-ignore
            <Is
              type={type}
              invalid={errorVisible}
              error={errorMessage}
              {...rest}
              {...inputRest}
              // TODO: research on a better way how to exclude functions from prop shallow comparison
              onFocus={this.handler(onFocus, this.props.onFocus!)}
              onBlur={this.handler(onBlur, this.props.onBlur!)}
              onChange={this.handler(onChange, this.props.onChange!)}
            />
          );
        }}
      </Field>
    );
  }
}
