import React from 'react';
import classnames from 'classnames';

import Input, { Props as InputProps } from '@/components/Form/Input';

import style from './TextInput.sass';

export interface Props extends InputProps {
  invalid?: boolean;
}

const TextInput: React.FC<Props> = ({ invalid, className, ...rest }) => (
  <Input className={classnames(style.root, { [style.invalid]: invalid }, className)} {...rest} />
);

export default TextInput;
