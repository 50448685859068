import React from 'react';
import classnames from 'classnames';

import TextInput, { Props as TextInputProps } from '@/components/Form/TextInput';

import style from './AutoHeightInput.sass';

interface Props extends TextInputProps {}

const AutoHeightInput: React.FC<Props> = (props) => {
  const getValue = React.useCallback(
    (input: Props['value']) => {
      const min = 1;
      const max = 10;
      const value = `${input || ''}`;
      const lines = value.split(/\r?\n/).slice(0, max);
      const normalized =
        lines.length < min ? [...lines, ...Array.from({ length: min - lines.length }, () => '')] : lines;

      return normalized
        .map((line) => {
          const empty = line.replace(/[^\w]/g, '').length === 0;
          return empty ? '\xa0' : line;
        })
        .join('\r\n');
    },
    [props.value]
  );

  return (
    <div className={style.root}>
      <TextInput {...props} className={classnames(style.input, props.className)} />
      {/**
       // @ts-ignore */}
      <TextInput is="div" className={classnames(style.pre, props.className)}>
        {getValue(props.value)}
      </TextInput>
    </div>
  );
};

export default AutoHeightInput;
