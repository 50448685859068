import React from 'react';

import { withValidation, Props as WithValidationProps } from '@/components/hoc/withValidation';
import Label from '@/components/Form/Label';

export interface ComponentProps {
  id: string;
  className?: string;
}

export interface Props extends ComponentProps {
  label: React.ReactNode;
  inputClassName?: string;
}

export const withLabel = <P extends WithValidationProps>(
  Component: React.ComponentType<P>,
  staticProps?: Partial<Props>
): React.FC<P & Props> => {
  const WrapperComponent = withValidation<P & Props>(
    ({ invalid, error, label, className, inputClassName, ...rest }) => (
      <React.Fragment>
        <Label htmlFor={rest.id}>{label}</Label>

        <Component invalid={invalid} className={inputClassName} {...staticProps} {...rest} />
      </React.Fragment>
    )
  );

  return React.memo((props) => (
    <div className={props.className}>
      {/**
       // @ts-ignore */}
      <WrapperComponent {...props} />
    </div>
  ));
};
