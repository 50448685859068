import React from 'react';

import './Input.sass';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  is?: any;
  id: string;
}

export default class Input extends React.PureComponent<Props> {
  static defaultProps: Pick<Props, 'is'> = {
    is: 'input'
  };

  input = React.createRef<HTMLInputElement>();

  componentDidMount() {
    if (this.props.autoFocus) setTimeout(() => this.input.current && this.input.current.focus(), 100);
  }

  render() {
    const { is, ...rest } = this.props;

    const Component = is;

    return <Component {...rest} ref={this.input} />;
  }
}
