import React from 'react';
import { Form as OriginalForm, FormProps, FormRenderProps } from 'react-final-form';
import { FORM_ERROR, FormApi } from 'final-form';
import set from 'lodash/set';

import { yup } from '@/util/yup';
import { ValidationError } from '@/components/hoc/withValidation';
import ErrorBoundary from '@/components/ErrorBoundary';

import FormField from './FormField';

interface BackendError extends Error {
  status: number;
  fields?: {
    [x: string]: string;
  };
  error_message: string;
}

export type ErrorHandler<T> = (error: T) => string | object | undefined;

interface ErrorHandlerMap {
  [code: number]: ErrorHandler<BackendError>;
  default: ErrorHandler<Error | BackendError>;
}

export const handleError = (map: ErrorHandlerMap) => (error: BackendError) => {
  const handler = map[error.status] || map.default;

  return handler(error) || map.default(error);
};

export type RenderProps<T> = FormRenderProps<T>;

export type Props<T extends object> = Omit<FormProps<T>, 'onSubmit'> & {
  schema?: yup.ObjectSchema<T>;
  children: (form: RenderProps<T>) => Object;
  onSubmit: (values: T, form: FormApi) => Promise<Object | null | void> | Object | null | void;
};

export default class Form<T extends object> extends React.PureComponent<Props<T>> {
  static Field = FormField;

  private getValue = (values: T) => {
    return this.props.schema ? this.props.schema.cast(values) : values;
  };

  private validate = (values: object) => {
    if (!this.props.schema) return values;

    return this.props.schema
      .validate(values, { abortEarly: false })
      .then(() => null)
      .catch((error: yup.ValidationError) => {
        const fields = error.inner.reduce((fields, error) => {
          // let's pass both the error message (which is the key to localize) and
          // the params so it can be formatted where it's displayed
          set(fields, error.path, { id: error.message, values: error.params as any });
          return fields;
        }, {} as { [x: string]: ValidationError });

        return fields;
      });
  };

  submit = (values: object, form: FormApi): Promise<Object | null> => {
    return Promise.resolve()
      .then(() => this.props.onSubmit(this.getValue(values as T), form))
      .catch(() => `I am sorry, but it looks like something went wrong.`)
      .then((result: any) => {
        if (typeof result === 'string') {
          return {
            [FORM_ERROR]: result
          };
        }

        return result;
      });
  };

  render() {
    const { schema, children, ...rest } = this.props;

    return (
      <ErrorBoundary>
        {/**
        // @ts-ignore */}
        <OriginalForm {...rest} validate={this.validate} onSubmit={this.submit} render={(form) => children(form)} />
      </ErrorBoundary>
    );
  }
}
