import { api } from './api';

export interface MessageSendRequest {
  name: string;
  email: string;
  message: string;
  token: string;
}

export const sendMessage = (request: MessageSendRequest) => {
  return api.post<null>(`/messages`, request);
};
