import React from 'react';
import classnames from 'classnames';

import Is, { Props as IsProps, IsComponent } from '@/components/Is';

import style from './Link.sass';

export type Props<P extends IsComponent> = IsProps<P> & {
  className?: string;
};

class Link<P extends IsComponent> extends React.PureComponent<Props<P>> {
  static defaultProps: Pick<Props<any>, 'is'> = {
    is: 'a'
  };

  render() {
    const { className, ...rest } = this.props;

    // @ts-ignore
    return <Is {...rest} className={classnames(style.root, this.props.className)} />;
  }
}

export default Link;
