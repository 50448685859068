import React from 'react';
import classnames from 'classnames';

import style from './Label.sass';

export interface Props {
  htmlFor: string;
  className?: string;
}

const Label: React.FC<Props> = (props) => <label {...props} className={classnames(style.root, props.className)} />;

export default Label;
