import React from 'react';

export interface Props {}

export interface ValidationError {
  id: string;
  values: any;
}

interface ExtraProps {
  invalid?: boolean;
  error?: ValidationError | null;
}

export const withValidation = <P extends Props>(Component: React.ComponentType<any>, staticProps?: Partial<Props>) =>
  class WithValidation extends React.PureComponent<P & ExtraProps> {
    render() {
      const { invalid, error, ...rest } = this.props;

      return <Component invalid={!!error} {...staticProps} {...rest} />;
    }
  };
